<template>
    <section>
        <b-card>
            <validation-observer ref="userProfile">
                <form>
                    <b-row>
                        <b-col md="12">
                            <h3>{{ $route.params.id_agent ? 'Modifica' : 'Nuovo' }} Agente</h3>
                        </b-col>

                        <b-col md="4">
                            <b-form-group label="Nome">
                                <validation-provider #default="{ errors }" name="Nome" rules="required">
                                    <b-form-input v-model="agent.agent_first_name"
                                        :state="errors.length > 0 ? false : null">
                                    </b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="4">
                            <b-form-group label="Cognome">
                                <validation-provider #default="{ errors }" name="Cognome" rules="required">
                                    <b-form-input v-model="agent.agent_last_name"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="4">
                            <b-form-group label="Email">
                                <validation-provider #default="{ errors }" name="Email" rules="required">
                                    <b-form-input v-model="agent.agent_email"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="4">
                            <b-form-group label="Codice Fiscale">
                                <validation-provider #default="{ errors }" name="Codice Fiscale">
                                    <b-form-input v-model="agent.agent_tax_code"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>

                        <b-col md="4">
                            <b-form-group label="Partita IVA">
                                <validation-provider #default="{ errors }" name="Partita IVA">
                                    <b-form-input v-model="agent.agent_vat_number"></b-form-input>
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>

                        <b-col md="4">
                            <b-form-group>
                                <b-button :class="'submit-button'" type="submit"
                                    v-on:click.prevent="saveAgent()">Salva</b-button>
                            </b-form-group>
                        </b-col>

                    </b-row>
                </form>

            </validation-observer>
        </b-card>
    </section>
</template>

<style>
@media screen and (min-width: 768px) {
    .submit-button {
        margin-top: 25px;
    }

}
</style>

<script>
import {
    BAvatar,
    BButton,
    BCard,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BModal,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";
import { required, email } from "@validations";
import { Requests } from "@/api/requests.js";

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        BAvatar,
        BButton,
        BCard,
        BFormGroup,
        BFormInput,
        BRow,
        BCol,
        BModal,
    },

    data: () => ({
        agent: {},

        //Validation Rules

        email,
        required,
    }),

    created() {
        this.getAgentDetails();
        localize("it");
    },

    methods: {
        async getAgentDetails() {
            if (this.$route.params.id_agent) {
                let data = new FormData();

                data.append("id", this.$route.params.id_agent);

                try {
                    const response = await Requests.getAgentDetails(data);
                    this.agent = response;
                } catch (err) {
                    console.log(err);
                }
            }
        },
        async saveAgent() {
            let data = new FormData();
            data.append('agent_first_name', this.agent.agent_first_name);
            data.append('agent_last_name', this.agent.agent_last_name);
            data.append('agent_email', this.agent.agent_email);
            data.append('agent_vat_number', this.agent.agent_vat_number ?? '');
            data.append('agent_tax_code', this.agent.agent_tax_code ?? '');
            try {
                if (!this.$route.params.id_agent) {
                    
                    const response = await Requests.createAgent(data);
                    if (response.agent_create.success == 'true') {
                        this.$swal('OK', 'I dati sono stati salvati', 'success');
                    }

                } else {
                    data.append('id_agent', this.$route.params.id_agent);
                    const response = await Requests.updateAgent(data);
                    if (response.agent_update.success == 'true') {
                        this.$swal('OK', 'I dati sono stati salvati', 'success');
                    }
                }

            } catch (err) {
                console.log(err);
            }
        }
    },
};
</script>