var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('validation-observer',{ref:"userProfile"},[_c('form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h3',[_vm._v(_vm._s(_vm.$route.params.id_agent ? 'Modifica' : 'Nuovo')+" Agente")])]),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nome"}},[_c('validation-provider',{attrs:{"name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.agent.agent_first_name),callback:function ($$v) {_vm.$set(_vm.agent, "agent_first_name", $$v)},expression:"agent.agent_first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Cognome"}},[_c('validation-provider',{attrs:{"name":"Cognome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.agent.agent_last_name),callback:function ($$v) {_vm.$set(_vm.agent, "agent_last_name", $$v)},expression:"agent.agent_last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.agent.agent_email),callback:function ($$v) {_vm.$set(_vm.agent, "agent_email", $$v)},expression:"agent.agent_email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Codice Fiscale"}},[_c('validation-provider',{attrs:{"name":"Codice Fiscale"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.agent.agent_tax_code),callback:function ($$v) {_vm.$set(_vm.agent, "agent_tax_code", $$v)},expression:"agent.agent_tax_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Partita IVA"}},[_c('validation-provider',{attrs:{"name":"Partita IVA"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{model:{value:(_vm.agent.agent_vat_number),callback:function ($$v) {_vm.$set(_vm.agent, "agent_vat_number", $$v)},expression:"agent.agent_vat_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',[_c('b-button',{class:'submit-button',attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.saveAgent()}}},[_vm._v("Salva")])],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }